import { Flex, Image } from "antd";
import { Navigate } from "react-router-dom";
import {
  RouteElement,
  RouteGroupElement,
} from "../stores/appStores/ApplicationStore";
import PayAndViewBalance from "../pages/AccountSummaryGroup/PayAndViewBalance";
import LoginSettings from "../pages/AccountSummaryGroup/LoginSettings";
import MailArchive from "../pages/AccountSummaryGroup/MailArchive/MailArchive";
import Compliance from "../pages/AccountSummaryGroup/Compliance/Compliance";
import iconPayAndViewBalance from "../images/pageIcons/AccountSummary/PayAndViewBalance.png";
import iconAccountSummary from "../images/pageIcons/AccountSummary/AccountSummary.png";
import iconMailArchive from "../images/pageIcons/AccountSummary/MailArchive.png";
import iconCompliance from "../images/pageIcons/AccountSummary/Compliance.png";
import iconArchitecturalRequests from "../images/pageIcons/AccountSummary/ArchitecturalRequests.png";
import ArchitecturalRequests from "../pages/AccountSummaryGroup/ArchitecturalRequests/ArchitecturalRequests";
import ArchitecturalRequestsForm from "../pages/AccountSummaryGroup/ArchitecturalRequests/ArchitecturalRequestsForm";
import { Role } from "../api/Common/CommonTypes";
import AccountContacts from "../pages/AccountSummaryGroup/AccountContacts/AccountContacts";
import EditContact from "../pages/AccountSummaryGroup/AccountContacts/EditContact";
import CreateContact from "../pages/AccountSummaryGroup/AccountContacts/CreateContact";

const iconStyle:React.CSSProperties = {width:"1.2em", marginLeft: "10px"}

const accountSummaryChildrenRoutes: RouteElement[] = [
  {
    key: "/",
    path: "",
    element: <Navigate to={`/`} />,
    featureID: null,
  },
  {
    key: "/PayAndViewBalance",
    path: "/PayAndViewBalance",
    icon: <Flex><Image style={iconStyle} src={iconPayAndViewBalance} preview={false} /></Flex>,
    label: "Pay and View Balance",
    element: <PayAndViewBalance />,
    featureID: 12,
  },
  {
    key: "/MailArchive",
    path: "/MailArchive",
    icon: <Flex><Image style={iconStyle} src={iconMailArchive} preview={false} /></Flex>,
    label: "Mail Archive",
    element: <MailArchive />,
    featureID: 3,
  },
  {
    key: "/Compliance",
    path: "/Compliance",
    icon: <Flex><Image style={iconStyle} src={iconCompliance} preview={false} /></Flex>,
    label: "Compliance Issues",
    element: <Compliance />,
    featureID: 13,
  },
  {
    key: "/ArchitecturalRequests",
    path: "/ArchitecturalRequests",
    icon: <Flex><Image style={iconStyle} src={iconArchitecturalRequests} preview={false} /></Flex>,
    label: "Architectural Requests",
    element: <ArchitecturalRequests />,
    featureID: 14,
  },
  {
    key: "/ArchitecturalRequests/Add",
    path: "/ArchitecturalRequests/Add",
    element: <ArchitecturalRequestsForm />,
    featureID: 14,
  },
  // {
  //   key: '/LoginSettings',
  //   path: '/LoginSettings',
  //   icon: <Image width={"1.2em"} src={docs} preview={false}/>,
  //   label: "Login Settings",
  //   element: <LoginSettings />
  // },
];
const AccountSummaryRoutes: RouteGroupElement[] = [
  {
    key: "/AccountInfo",
    path: "/AccountInfo",
    label: "Account Info",
    children: accountSummaryChildrenRoutes,
    role: [Role.BoardMember, Role.Homeowner],
  },
];

export default AccountSummaryRoutes;
