import { Col, Flex, List, Row, Space, Typography } from "antd";
import { Link } from "react-router-dom";

const Reports: React.FC = () => {
  const wFull = { width: "100%" };
  const mNone = { margin: 0 };
  type ReportData = { url: string; linkTitle: string; description: string };

  const AccountingReportData: ReportData[] = [
    // {
    //   url: "/BoardVue/Reports/CurrentBalancesWithAgingByResident",
    //   linkTitle: "Current Balances with Aging by Resident",
    //   description: "Current and/or previous resident balances, organized by aging buckets.",
    // },
    // {
    //   url: "/BoardVue/Reports/BalanceWithCategoriesByResident",
    //   linkTitle: "Balance with Categories by Resident",
    //   description: "Resident balances grouped by charge categories.",
    // },
    // {
    //   url: "/BoardVue/Reports/DelinquencySummary",
    //   linkTitle: "Delinquency Summary",
    //   description: "Delinquency details for owners with unpaid balances, including their status and a summary by posting code.",
    // },
    // {
    //   url: "/BoardVue/Reports/DelinquencyDetailLienAndDemand",
    //   linkTitle: "Delinquency Detail Lien and Demand",
    //   description: "Lien and demand fees, along with checkbox summary data, organized by date range.",
    // },
    // {
    //   url: "/BoardVue/Reports/DelinquencyDetailCheckboxSummary",
    //   linkTitle: "Delinquency Detail Checkbox Summary",
    //   description: "Lien and demand fees, along with checkbox summary data, organized by date range.",
    // },
    // {
    //   url: "/BoardVue/Reports/GeneralLedgerReport",
    //   linkTitle: "General Ledger",
    //   description: "Detailed summary of the association's financial transactions.",
    // },
    // {
    //   url: "/BoardVue/Reports/PaidInvoices",
    //   linkTitle: "Paid Invoices",
    //   description: "Searchable paid invoice images by date range, vendor, GL code, amount, or check number.",
    // },
  ];

  const HomeownerReportData: ReportData[] = [
    // {
    //   url: "/BoardVue/Reports/ArchitecturalStatusCSVExport",
    //   linkTitle: "Architectural Status CSV Export",
    //   description: "Architectural submissions categorized by date range, status, and type, available for CSV export.",
    // },
     // {
    //   url: "/BoardVue/Reports/ArchitecturalStatus",
    //   linkTitle: "Architectural Status",
    //   description: "Architectural submissions categorized by date range, status, and type, available for CSV export.",
    // },
    // {
    //   url: "/BoardVue/Reports/ComplianceDetail",
    //   linkTitle: "Compliance Detail",
    //   description: "Comprehensive summary of compliance issues within the association, including inspection dates, descriptions, statuses, and any charges.",
    // },
    {
      url: "/BoardVue/Reports/ResidentDirectory",
      linkTitle: "Resident Directory",
      description: "Contact information for residents.",
    },
    // {
    //   url: "/BoardVue/Reports/ResidentMoveIn",
    //   linkTitle: "Resident Move In",
    //   description: "Contact information and move-in dates for residents.",
    // },
    
    
  ];

  const CommunityGeneralInfoReportData: ReportData[] = [
    // {
    //   url: "/BoardVue/Reports/BodAndCommitteeDirectory",
    //   linkTitle: "BOD and Committee Directory",
    //   description: "Contact information for board members and committee members.",
    // },
    // {
    //   url: "/BoardVue/Reports/FAQsReport",
    //   linkTitle: "Property FAQs",
    //   description: "Frequently asked questions and answers about properties in the association.",
    // },
    
    // {
    //   url: "/BoardVue/Reports/CommunityVendorList",
    //   linkTitle: "Community Vendor List",
    //   description: "",
    // },
    
    // {
    //   url: "/BoardVue/Reports/VMSTrusteeSalesProgress",
    //   linkTitle: "VMS Trustee Sales Progress",
    //   description: "",
    // },
    // {
    //   url: "/BoardVue/Reports/ViolationChart",
    //   linkTitle: "Violation Chart",
    //   description: "",
    // },
    
    
  ];

  const renderListData = (item: ReportData) => (
    <List.Item>
      <Row style={wFull}>
        <Col xs={12} sm={10} md={8} lg={8} xl={6}>
          <Link to={item.url} style={{ marginLeft: 10 }}>
            {item.linkTitle}
          </Link>
        </Col>
        <Col xs={12} sm={14} md={16} lg={16} xl={18}>
          <Typography.Text>{item.description}</Typography.Text>
        </Col>
      </Row>
    </List.Item>
  );

  return (
    <>
      <Flex
        justify="space-between"
        wrap="wrap"
        align="center"
        gap="middle"
        style={{ padding: 10 }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Space style={{ flexWrap: "wrap" }}>
            <h2 style={{ width: "280px" }}>Reports</h2>
          </Space>
        </div>
      </Flex>
      <Flex vertical={true} gap={10}>
      {/* <List
          header={
            <Typography.Title level={5} style={mNone}>
              <b>Association Reports</b>
            </Typography.Title>
          }
          bordered
          dataSource={CommunityGeneralInfoReportData}
          renderItem={renderListData}
        /> */}
        <List
          header={
            <Typography.Title level={5} style={mNone}>
              <b>Homeowner Reports</b>
            </Typography.Title>
          }
          bordered
          dataSource={HomeownerReportData}
          renderItem={renderListData}
        />
        {/* <List
          header={
            <Typography.Title level={5} style={mNone}>
              <b>Accounting Reports</b>
            </Typography.Title>
          }
          bordered
          dataSource={AccountingReportData}
          renderItem={renderListData}
        /> */}
        
      </Flex>
    </>
  );
};

export default Reports;
