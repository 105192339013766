import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import { DocumentFilter, LocalTask, TaxonomyItem } from "../../api/BoardVue";
import { defaultRecordFilters } from "./BoardVue/RecordArchiveStore";
import BoardTaskStore from "./BoardVue/BoardTasks";
import { ReactElement } from "react";
import { RouteObject } from "react-router-dom";
import BoardVueRoutes from "../../Routes/BoardVueRoutes";
import AccountSummaryRoutes from "../../Routes/AccountSummaryRoutes";
import AssociationRoutes from "../../Routes/AssociationRoutes";
import FormsRoutes from "../../Routes/FormsRoutes";
import MainRoutes from "../../Routes/MainRoutes";
import { GuestPassType } from "../../api/Association/AssociationTypes";
import { AllFeaturesForCommunity, AllFeaturesForBoard, AllFeaturesForCommunityObject, CommunityProxy, Role } from "../../api/Common/CommonTypes";

export type RouteElement = {
  key: string;
  path: string;
  icon?: ReactElement;
  label?: string;
  element: ReactElement;
  featureID: number | null;
};
export type RouteGroupElement = RouteObject & {
  key: string;
  icon?: ReactElement;
  label: string | null;
  children?: RouteElement[];
  role: Role[];
  className?: string;
};

class ApplicationStore {
  SelectedAddress: CommunityProxy = {} as CommunityProxy;
  CommunityList: CommunityProxy[] = [];
  AllFeaturesForCommunity: AllFeaturesForCommunity[] = [];
  AllFeaturesForBoard: AllFeaturesForBoard[] = [];
  AllFeaturesForCommunityObject: AllFeaturesForCommunityObject = {};
  isLoading: boolean = true;
  isLoadingCommunity: boolean = false;
  ApiStore!: ApiStore;

  taskStatuses: string[] = [];
  reoccurrences: string[] = [];
  priorities: string[] = [];
  communityBoardTaskTypes: TaxonomyItem[] = [];
  communityCommittees: TaxonomyItem[] = [];
  users: TaxonomyItem[] = [];
  recordArchiveFilters: DocumentFilter[] = defaultRecordFilters;
  routingList: RouteGroupElement[] = [];
  guestPassTypes: GuestPassType[] = [];

  communityWebSite: string = "";

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
  }

  editLoading = (loading: boolean) => {
    this.isLoading = loading;
  };
  
  public async getCommunityWebSite(): Promise<void> {
    this.communityWebSite = await this.ApiStore.CommonApiClient.getCommunityWebSite() || "";
  }

  editRoutingList = (newRoutingList: RouteGroupElement[]) => {
    this.routingList = newRoutingList.filter((el) =>
      el.role.includes(this.SelectedAddress.role)
    );
  };

  setSelectedCommunityById = async (id: string) => {
    const selectedCommunity = this.CommunityList.find(
      (community) => String(community.id) === id
    );
    if (selectedCommunity) {
      await this.setSelectedCommunity(selectedCommunity);
    }
  };
  setSelectedBoard = async (data: []) => {
  
    if (data) {
      
      await this.setSelectedBoards(data);
    }
  };
  onTaskModelChange = (
    model: LocalTask,
    boardTaskStore: BoardTaskStore
  ) => {
    if (model.communityBoardTaskType) {
      model.communityBoardTaskTypeTermGuid =
        this.communityBoardTaskTypes?.find(
          (comm) => comm.id === model.communityBoardTaskType
        )?.termGuid ?? "";
    }

    if (model.communityCommittee) {
      model.communityCommitteeTermGuid =
        this.communityCommittees?.find(
          (comm) => comm.id === model.communityCommittee
        )?.termGuid ?? "";
    }
    boardTaskStore.taskModel = { ...model };
  };

  setSelectedCommunity = async (selectedCommunity: CommunityProxy) => {
    this.SelectedAddress = selectedCommunity;

    this.ApiStore.setConfigApi({
      ...this.ApiStore.configApi,
      community: selectedCommunity,
    });
    localStorage.setItem("addressId", selectedCommunity.id.toString());
    const res =
      await this.ApiStore.CommonApiClient.getAllFeaturesForCommunity();
    this.setAllFeaturesForCommunity(res);
    await this.getCommunityWebSite();
    this.editRoutingList([
      ...MainRoutes,
      ...AccountSummaryRoutes,
      ...AssociationRoutes,
      ...FormsRoutes,
      ...BoardVueRoutes,
    ]);
  };
  setSelectedBoards = async (selectedCommunity: AllFeaturesForBoard[]) => {
    this.AllFeaturesForBoard = selectedCommunity;
    // this.editRoutingList([
    //   ...BoardVueRoutes,
    // ]);
  };

  setCommunityList = (communityList: CommunityProxy[]) => {
    this.CommunityList = communityList;
  };

  isFeatures = (featureID: number | null): boolean => {
    const boardAsCommunityFeatures = this.AllFeaturesForBoard.map(boardFeature => ({
      communityId: null, 
      endpoint: null,
      exceptionText: null,
      featureId: boardFeature.id,
      id: null,
      isActive: boardFeature.isBoardVuePermission,
      sharedSecret: null,
      isBoardVuePermission: boardFeature.isBoardVuePermission,
    }));
    const merged = [
      ...this.AllFeaturesForCommunity,
      ...boardAsCommunityFeatures,
    ];
    return (
      featureID === null || merged.find((i) => i.featureId === featureID)?.isActive === true
    );
  };

  setAllFeaturesForCommunity = (
    newAllFeaturesForCommunity: AllFeaturesForCommunity[]
  ) => {
    this.AllFeaturesForCommunity = newAllFeaturesForCommunity;
    const newAllFeaturesForCommunityObject: AllFeaturesForCommunityObject = {
      CreateArchitecturalRequestFeature: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 1
      ),
      FAQMenuFeature: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 2
      ),
      MailArchiveMenuFeature: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 3
      ),
      eStatementsMenuFeature: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 4
      ),
      eTrakRoomSchedules: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 7
      ),
      MembershipCard: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 8
      ),
      NOTUSED: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 9
      ),
      TicketsandFitness: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 10
      ),
      GuestPasses: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 11
      ),
      PayandViewBalance: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 12
      ),
      Compliance: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 13
      ),
      ArchitecturalRequests: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 14
      ),
      ContactsandLinks: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 15
      ),
      ResidentDirectory: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 16
      ),
      PropertyDocuments: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 17
      ),
      ComplianceConcernemail: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 18
      ),
      MaintenanceRequestemail: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 19
      ),
      ContactInfoChange: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 20
      ),
      FeeWaiver: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 21
      ),
      Notifications: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 22
      ),
      ComplianceConcernSP: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 23
      ),
      MaintenanceRequestSP: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 24
      ),
      EntryAccessRequest: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 25
      ),
      Election: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 26
      ),
      ABDIGatePasses: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 27
      ),
      SCHHTickets: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 28
      ),
      SCHHFitnessClasses: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 29
      ),
      TicketsandRecreation: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 30
      ),
      TenantRegistration: newAllFeaturesForCommunity.find(
        (el: AllFeaturesForCommunity) => el.featureId === 31
      )
    };
    this.AllFeaturesForCommunityObject = newAllFeaturesForCommunityObject;
  };

  saveCommunitiesToLocalStorage = (communities: CommunityProxy[]) => {
    const cacheData = {
      addresses: communities,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem("addressesForCommunities", JSON.stringify(cacheData));
  };

  clearCommunitiesLocalStorage = () => {
    localStorage.removeItem("addressesForCommunities");
  };

  getCommunitiesFromLocalStorage = (): CommunityProxy[] | null => {
    const cachedData = localStorage.getItem("addressesForCommunities");
    if (cachedData) {
      const { addresses, timestamp } = JSON.parse(cachedData);
      const currentTime = new Date().getTime();
      const cacheDuration =
        parseInt(process.env.REACT_APP_CACHING_COMUNITY_DAYS as string) *
        24 *
        60 *
        60 *
        1000;
      if (currentTime - timestamp < cacheDuration && addresses.length > 0) {
        return addresses;
      } else {
        return null;
      }
    }
    return null;
  };

  saveDropdownToLocalStorage = (name: string, data: any[]) => {
    const cacheData = {
      data: data,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(name, JSON.stringify(cacheData));
  };

  getDropdownFromLocalStorage = (name: string): any[] | null => {
    const cachedData = localStorage.getItem(name);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const currentTime = new Date().getTime();
      const cacheDuration =
        parseInt(process.env.REACT_APP_CACHING_DROPDOWNDS_DAYS as string) *
        24 *
        60 *
        60 *
        1000;
      if (currentTime - timestamp < cacheDuration) {
        return data;
      }
    }
    return null;
  };
  clearDropdownsLocalStorages = (): void => {
    localStorage.removeItem("taskStatuses");
    localStorage.removeItem("priorities");
    localStorage.removeItem("reoccurrences");
    localStorage.removeItem("communityBoardTaskTypes");
    localStorage.removeItem("communityCommittees");
    localStorage.removeItem("users");
    localStorage.removeItem("recordArchiveFilters");
    localStorage.removeItem("guestPassTypes");
  };
  updateDropdowns = async (): Promise<unknown[]> => {
    const statues = new Promise(async (resolve, reject) => {
      try {
        const localData = this.getDropdownFromLocalStorage("taskStatuses");
        if (localData) {
          this.taskStatuses = localData;
          resolve({ status: localData });
        } else {
          const responseTaskStatuses =
            await this.ApiStore.BoardVueApiClient.getSharePointField(
              "TaskStatus"
            );
          if (responseTaskStatuses.status === 200) {
            this.taskStatuses = responseTaskStatuses.data;
            this.saveDropdownToLocalStorage(
              "taskStatuses",
              responseTaskStatuses.data
            );
            resolve({ status: responseTaskStatuses });
          } else {
            reject(
              "TaskSatuses don`t load :" +
              JSON.stringify(responseTaskStatuses, undefined, 2)
            );
          }
        }
      } catch (error) {
        reject(
          "TaskSatuses don`t load :" + JSON.stringify(error, undefined, 2)
        );
      }
    });
    const priorities = new Promise(async (resolve, reject) => {
      try {
        const localData = this.getDropdownFromLocalStorage("priorities");
        if (localData) {
          this.priorities = localData;
          resolve({ priorities: localData });
        } else {
          const responsePriorities =
            await this.ApiStore.BoardVueApiClient.getSharePointField(
              "Priority"
            );
          if (responsePriorities.status === 200) {
            this.priorities = responsePriorities.data;
            this.saveDropdownToLocalStorage(
              "priorities",
              responsePriorities.data
            );
            resolve({ priorities: responsePriorities });
          } else {
            reject(
              "Priorities don`t load :" +
              JSON.stringify(responsePriorities, undefined, 2)
            );
          }
        }
      } catch (error) {
        reject("Priorities don`t load :" + JSON.stringify(error, undefined, 2));
      }
    });
    const reoccurrences = new Promise(async (resolve, reject) => {
      try {
        const localData = this.getDropdownFromLocalStorage("reoccurrences");
        if (localData) {
          this.reoccurrences = localData;
          resolve({ reoccurrences: localData });
        } else {
          const responseReoccurrences =
            await this.ApiStore.BoardVueApiClient.getSharePointField(
              "Reoccurrence"
            );
          if (responseReoccurrences.status === 200) {
            this.reoccurrences = responseReoccurrences.data;
            this.saveDropdownToLocalStorage(
              "reoccurrences",
              responseReoccurrences.data
            );
            resolve({ reoccurrences: responseReoccurrences });
          } else {
            reject(
              "Reoccurrences don`t load :" +
              JSON.stringify(responseReoccurrences, undefined, 2)
            );
          }
        }
      } catch (error) {
        reject(
          "Reoccurrences don`t load :" + JSON.stringify(error, undefined, 2)
        );
      }
    });
    const communityBoardTaskTypes = new Promise(async (resolve, reject) => {
      try {
        const localData = this.getDropdownFromLocalStorage(
          "communityBoardTaskTypes"
        );
        if (localData) {
          this.communityBoardTaskTypes = localData;
          resolve({ communityBoardTaskTypes: localData });
        } else {
          const responseCommunityBoardTaskTypes =
            await this.ApiStore.BoardVueApiClient.getSharePointCommunityBoardTaskTypes();
          if (
            responseCommunityBoardTaskTypes &&
            responseCommunityBoardTaskTypes.length > 0
          ) {
            this.communityBoardTaskTypes = responseCommunityBoardTaskTypes;
            this.saveDropdownToLocalStorage(
              "communityBoardTaskTypes",
              responseCommunityBoardTaskTypes
            );
            resolve({
              communityBoardTaskTypes: responseCommunityBoardTaskTypes,
            });
          } else {
            reject(
              "CommunityBoardTaskTypes empty or don`t load :" +
              JSON.stringify(responseCommunityBoardTaskTypes, undefined, 2)
            );
          }
        }
      } catch (error) {
        reject(
          "CommunityBoardTaskTypes empty or don`t load :" +
          JSON.stringify(error, undefined, 2)
        );
      }
    });
    const communityCommittees = new Promise(async (resolve, reject) => {
      try {
        const localData = this.getDropdownFromLocalStorage(
          "communityCommittees"
        );
        if (localData) {
          this.communityCommittees = localData;
          resolve({ communityBoardTaskTypes: localData });
        } else {
          const responseCommunityCommittees =
            await this.ApiStore.BoardVueApiClient.getSharePointCommunityCommittees();
          if (
            responseCommunityCommittees &&
            responseCommunityCommittees.length > 0
          ) {
            this.communityCommittees = responseCommunityCommittees;
            this.saveDropdownToLocalStorage(
              "communityCommittees",
              responseCommunityCommittees
            );
            resolve({ communityCommittees: responseCommunityCommittees });
          } else {
            reject(
              "Community Committees empty or don`t load :" +
              JSON.stringify(responseCommunityCommittees, undefined, 2)
            );
          }
        }
      } catch (error) {
        reject(
          "Community Committees empty or don`t load :" +
          JSON.stringify(error, undefined, 2)
        );
      }
    });
    const users = new Promise(async (resolve, reject) => {
      try {
        const localData = this.getDropdownFromLocalStorage("users");
        if (localData) {
          this.users = localData;
          resolve({ users: localData });
        } else {
          const responseUsers =
            await this.ApiStore.BoardVueApiClient.getUsers();
          if (responseUsers.status === 200) {
            this.users = responseUsers.data;
            this.saveDropdownToLocalStorage("users", responseUsers.data);
            resolve({ users: responseUsers.data });
          } else {
            reject(
              "Users don`t load :" + JSON.stringify(responseUsers, undefined, 2)
            );
          }
        }
      } catch (error) {
        reject("Users don`t load :" + JSON.stringify(error, undefined, 2));
      }
    });
    const recordArchiveFilters = new Promise(async (resolve, reject) => {
      try {
        const localData = this.getDropdownFromLocalStorage(
          "recordArchiveFilters"
        );
        if (localData) {
          this.recordArchiveFilters = localData;
          resolve({ recordArchiveFilters: localData });
        } else {
          const responseFilters =
            await this.ApiStore.BoardVueApiClient.getSharePointRecordArchiveFilters();
          if (responseFilters.status === 200) {
            this.recordArchiveFilters = responseFilters.data;
            this.saveDropdownToLocalStorage(
              "recordArchiveFilters",
              responseFilters.data
            );
            resolve({ recordArchiveFilters: responseFilters.data });
          } else {
            reject(
              "Error fetching SharePoint filters:" +
              JSON.stringify(responseFilters, undefined, 2)
            );
          }
        }
      } catch (error) {
        reject(
          "Error fetching SharePoint filters:" +
          JSON.stringify(error, undefined, 2)
        );
      }
    });
    const guestPassTypes = new Promise(async (resolve, reject) => {
      try {
        if(this.AllFeaturesForCommunityObject.GuestPasses == undefined || !this.AllFeaturesForCommunityObject.GuestPasses?.isActive){
          resolve({ guestPassTypes: [] });
          return;
        }
        const localData = this.getDropdownFromLocalStorage(
          "guestPassTypes"
        );
        if (localData) {
          this.guestPassTypes = localData;
          resolve({ guestPassTypes: localData });
        } else {
          const authString = await this.ApiStore.AssociationApiClient.getAuthString();
          const dWellingCommunityData = await this.ApiStore.AssociationApiClient.getDWellingCommunityData();
          const responseGuestPassTypes = await this.ApiStore.AssociationApiClient.getDwPassTypesForCommunity(authString, dWellingCommunityData.dlCommunityId, dWellingCommunityData.dlHomeId);
          this.guestPassTypes = responseGuestPassTypes;
          this.saveDropdownToLocalStorage(
            "guestPassTypes",
            responseGuestPassTypes
          );
          resolve({ guestPassTypes: responseGuestPassTypes });
        }
      } catch (error) {
        reject(
          "Error fetching guestPassTypes" +
          JSON.stringify(error, undefined, 2)
        );
      }
    });
    const getAllDropdowns = [
      statues,
      priorities,
      reoccurrences,
      communityBoardTaskTypes,
      communityCommittees,
      users,
      recordArchiveFilters,
      guestPassTypes
    ];
    return Promise.all(getAllDropdowns);
  };
}
export default ApplicationStore;
