import { observer } from "mobx-react-lite";
import useBreakpoint from 'use-breakpoint'
import { Space, Table, Button, Input, Flex, Empty } from 'antd';
import { SearchOutlined} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { AnnouncementItem } from "../../../api/BoardVue";
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../../root-store-context";
import { dateValidation } from "../../../helpers/dateValidation";
import { DateSort, FileNaturalCompare } from "../../../helpers/SorterHelper";

const { Column } = Table;


const Announcements = observer(() => {

    const { apiStore } = useStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [announcements, setAnnouncements] = useState<Array<AnnouncementItem>>([]);
    const [searchText, setSearchText] = useState("");
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(1);
    }, [announcements, searchText])

    useEffect(() => {
        setIsLoading(true);
        apiStore.BoardVueApiClient.getAnnouncements()
            .then(data => {
                if(data!=null){
                    setAnnouncements(data);
                }
                setIsLoading(false)
            }).catch((error) => { 
                console.error("Error fetching items:", error);
                setIsLoading(false);
            });
    }, [])

    const onRowClck = (item: AnnouncementItem) => {
        navigate(`/BoardVue/Announcement/${item.id}`);
    }

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{padding:10}}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Announcements</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }}/>
                </Space>
            </div>
        </Flex>

        <Table dataSource={announcements.map((el, index) => { return { ...el, key: index } })}
            scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
            pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive: true, current: currentPage, onChange: (pageNum) => setCurrentPage(pageNum) }}
            loading={isLoading}
            size={breakpoint === "mobile" ? "small" : "large"}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => { onRowClck(record) },
                };
            }}
            locale={{ emptyText: <Empty description={'No Announcements'} />}}>
            <Column align='left' width="40%" title="Title" dataIndex="title" key="title"
                sorter={(a: AnnouncementItem, b: AnnouncementItem, direction) => FileNaturalCompare(a.title, b.title, direction)}
                filteredValue={[searchText]}
                onFilter={(text, record) => { return String(record.title).toLowerCase().includes(String(text).toLowerCase()) }}
            />
            <Column width="8%" title="Modified" dataIndex="modified" key="modified"
            sorter={(a, b) => DateSort(a, b, "modified")}
                render={(date) => dateValidation(date)}
            />
            {/* <Column width="8%" title="Expires" dataIndex="expires" key="expires"
            sorter={(a, b) => DateSort(a, b, "expires")}
                render={(date) => dateValidation(date)}
            /> */}
        </Table>
    </>
})

export default Announcements