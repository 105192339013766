import { App, Button, Col, Form, Input, Row, Space, UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import FileHelper from "../../../helpers/FileHelper";
import Dragger from "antd/es/upload/Dragger";
import CustomUploadItem from "../../BoardVueGroup/BoardTasks/CustomUploadItem";
import { RcFile } from "antd/es/upload";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../root-store-context";
import { ComplianceFormType } from "../../../api/Association/AssociationTypes";
import { Attachment } from "../../../api/Common/CommonTypes";
import { getPhoneFormat } from "../../../helpers/phoneFormat";

const ComplianceConcernForm = observer(() => {
  const { complianceConcernStore, modalViewerStore, apiStore } = useStore();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  const [fileList, setFileList] = useState<RcFile[]>([]);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };

  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <>
      <span style={{ fontWeight: 500 }}>{label}</span>
    </>
  );

  const validateZipCode = (zipCode: string): boolean => {
    return zipCode.length >= 5 && zipCode.length <= 7;
  };

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      if (file.status === "done") {
        return false;
      }

      const updatedFileList = fileList.filter(
        (attachment) => attachment.uid !== file.uid
      );
      setFileList(updatedFileList);
      if (complianceConcernStore.attachments !== undefined) {
        const updatedAttachments = complianceConcernStore.attachments.filter(
          (attachment) => attachment.Guid !== file.uid
        );
        complianceConcernStore.attachments = updatedAttachments;
      }
    },

    onDownload: (file) => {
      if (file.status === "done") {
        apiStore.FormsApiClient.getFile(file.uid)
          .then((res) => {
            if (modalViewerStore)
              modalViewerStore.openFileByData(res.data, file.name);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    beforeUpload: (file, allFiles) => {
      console.log(allFiles);
      if (allFiles.length + fileList.length > 10) {
        if (allFiles[0].uid === file.uid) {
          message.error("You can attach not more than 10 files.");
        }
        return false;
      }
      var isExist = fileList.some((i) => i.name === file.name);
      if (isExist) {
        message.error("File with the same name already exists.");
        return false;
      }
      if (allFiles.some((i: File) => i.size > 10 * 1024 * 1024)) {
        if (file.size > 10 * 1024 * 1024) {
          message.error(
            "Unacceptable file size. File upload are limited to 10MB."
          );
        }
        return false;
      }
      setFileList((prev) => [...prev, file]);
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const byteArray = new Uint8Array(arrayBuffer);
        const newAttachment: Attachment = {
          Name: file.name,
          Data: FileHelper.ArrayBufferToBase64(Array.from(byteArray)),
          Guid: file.uid,
          Url: "",
        };
        if (complianceConcernStore?.attachments !== undefined)
          complianceConcernStore.attachments = [
            ...complianceConcernStore.attachments,
            newAttachment,
          ];
      };
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
  };

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        complianceConcernStore.setValidForm(true);
      },
      () => {
        complianceConcernStore.setValidForm(false);
      }
    );
  }, [values]);

  const submit = async () => {
    await complianceConcernStore.saveForm();
    message.success("Compliance Concern Send");
    await resetForm();
  };

  const resetForm = async () => {
    setFileList([]);
    await complianceConcernStore.resetForm();
    form.resetFields();
  };

  return (
    <>
      <Form
        form={form}
        variant="outlined"
        layout="vertical"
        style={{ maxWidth: 900 }}
        initialValues={{ ...complianceConcernStore.complianceForm }}
        onValuesChange={(
          changedValues: Partial<ComplianceFormType>,
          allValues: ComplianceFormType
        ) => {
          complianceConcernStore.setForm(allValues);
        }}
        onFinish={submit}
        labelCol={{ span: 24 }}
        validateMessages={validateMessages}
        requiredMark={customizeRequiredMark}
        autoComplete="off"
        scrollToFirstError={{ block: "center", inline: "nearest" }}
      >
        <Row>
          <Col span={24}>
            {complianceConcernStore.complianceDisclaimer &&
              complianceConcernStore.complianceDisclaimer?.length > 0 && (
                <p>{complianceConcernStore.complianceDisclaimer}</p>
              )}
            <Form.Item<ComplianceFormType>
              label="Daytime Phone"
              name={"DaytimePhone"}
              style={{ width: "100%" }}
              rules={[{ required: true, message: 'Please input your daytime phone!', min: 12 }]}>
              <Input onChange={(event) => {
                const { value } = event.target;
                const formattedValue = getPhoneFormat(value);
                form.setFieldsValue({
                  DaytimePhone: formattedValue,
                });

              }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item<ComplianceFormType>
              label="Email Address"
              name="EmailAddress"
              rules={[{ required: true }, { type: "email" }]}
            >
              <Input pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item<ComplianceFormType>
              label="Non Compliance Address"
              name="NonComplianceAddress"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item<ComplianceFormType>
              label="Non Compliance City"
              name="NonComplianceCity"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item<ComplianceFormType>
              label="Non Compliance State"
              name="NonComplianceState"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value: string) {
                    if (value) {
                      if (value.length === 2 && /^[a-zA-Z]{2}$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error(
                            "Please enter a valid state code. State codes should be entered as a two-letter abbreviation."
                          )
                        );
                      }
                    } else {
                      return Promise.reject(new Error());
                    }
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item<ComplianceFormType>
              label="Non Compliance Zip"
              name="NonComplianceZip"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value: string) {
                    if (value) {
                      if (validateZipCode(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("Please enter a valid ZIP.")
                        );
                      }
                    } else {
                      return Promise.reject(new Error());
                    }
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {complianceConcernStore && (
          <Row>
            <Col span={24}>
              <div>
                <Dragger
                  fileList={fileList}
                  multiple
                  {...uploadProps}
                  itemRender={CustomUploadItem}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: "#5a7890" }} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Dragger>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24} style={{ textAlign: "right", marginTop: "16px" }}>
            <Space>
              <Button size="middle" onClick={() => resetForm()}>
                Cancel
              </Button>
              <Button size="middle" type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default ComplianceConcernForm;
