import axios from "axios";
import CryptoJS from "crypto-js";
import { ConfigApi } from "./ConfigApi";
import Swal from "sweetalert2";

export type Tuple<type1, type2> = {
  item1: type1;
  item2: type2;
};

class MobileWebApiClient {
  private BASE_URL: string;
  private BASE_URLRIC: string;
  private publicKey: string;
  protected _configApi: ConfigApi;
  private xApiKey: string;

  constructor(configApi: ConfigApi) {
    this.BASE_URL = process.env.REACT_APP_API_ENDPOINT as string;
    this.publicKey = process.env.REACT_APP_PUBLIC_KEY as string;
    this._configApi = configApi;

    this.BASE_URLRIC = process.env.REACT_APP_API_URL as string;
    this.xApiKey = process.env.REACT_APP_XAPI_KEY as string;
      

  }

  private randomString = (): string => Math.random().toString(36).slice(-8);

  private formatString = (
    inputString: string,
    desiredLength: number,
    paddingChar: string
  ): string => {
    let formattedString = inputString;
    while (formattedString.length < desiredLength) {
      formattedString += paddingChar;
    }
    return formattedString;
  };

  private getHeaders = (contentType = "application/json") => {
    let randomString = this.randomString();
    let apikey = this.publicKey === undefined ? "" : this.publicKey;
    let KEY = this.formatString(
        apikey.replace(/[-:;,._\\@/*&^%$#!()=+]/g, ""),
        32,
        "0"
    );
    let IV = this.formatString(
        `PT${randomString}JAq113vgkeGWPkCEStbILNJCCUfaAgxk5${randomString}q1EfBshryYLoedKxjDqxWKFRf6E${randomString}ZUC5y0CNLWHaIDJU6ozwUVLao${randomString}`.replace(
            /[-:;,._\\@/*&^%$#!()=+]/g,
            ""
        ),
        16,
        "0"
    );
    let key = CryptoJS.enc.Utf8.parse(KEY);
    let iv = CryptoJS.enc.Utf8.parse(IV);
    let encrypted;
    let srcs = CryptoJS.enc.Utf8.parse(this.publicKey);

    encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return {
        "public-key": encrypted.ciphertext.toString(),
        www: IV,
        Authorization: `bearer ${this._configApi.authToken}`,
        "X-Api-Key": this.publicKey,
        "Content-Type": contentType, // Set Content-Type based on parameter
    };
};

  protected get = async (url: string): Promise<any> => {
    console.log("callproduction1");
    return await axios({
      method: "get",
      baseURL: this.BASE_URL,
      url: url,
      headers: this.getHeaders(),
    });
  };

  protected post = async (
    url: string,
    data?: Record<any, any> | string
  ): Promise<any> => {
    const config = {
      method: "post",
      url: url,
      baseURL: this.BASE_URL,
      headers: this.getHeaders(),
      ...(data !== undefined && { data: data }),
    };
    return await axios(config);
  };

  protected tryGet = async (url: string, noContentIsOk: boolean = false): Promise<any> => {
    console.log("callproduction2");
    try {
      const res = await axios({
        method: "get",
        baseURL: this.BASE_URL,
        url: url,
        headers: this.getHeaders(),
      });
     console.log('getresp',res);
      if (res.statusText === 'OK' || (noContentIsOk && res.statusText === 'No Content')) {
        return res;
      }
      this._configApi.notification.error({
        message: 'Network Error',
        description: <><b>URL: </b>{url}<br /><b>STATUS TEXT: </b>{res.statusText}<br /><b>STATUS: </b>{res.status}</>,
        duration: 10,
      });
      console.log(`url:`, url);
      console.log(`response:`, res);
      return undefined;
    } catch (error) {
      this._configApi.notification.error({
        message: 'Network Error',
        description: <><b>URL: </b>{url}<br /></>,
        duration: 10,
      });
      console.log(`url:`, url);
      return undefined;
    }
  };
  protected tryGetLocalhost = async (url: string, noContentIsOk: boolean = false): Promise<any> => {

    try {
      const res = await axios({
        method: "get",
        baseURL: this.BASE_URL,
        url: url,
        headers: this.getHeaders(),
      });
      console.log(`response:`, res);
      if (res.status == 200) {
        return res.data;
      }
      this._configApi.notification.error({
        message: 'API Error',
        description: <><b>URL: </b>{url}<br /><b>STATUS TEXT: </b>{res.statusText}<br /><b>STATUS: </b>{res.status}</>,
        duration: 10,
      });     
      return undefined;
    } catch (error) {
      this._configApi.notification.error({
        message: 'Network Error',
        description: <><b>URL: </b>{url}<br /></>,
        duration: 10,
      });
      console.log(`url:`, url);
      return undefined;
    }
  };

  protected tryPost = async (
    url: string,
    data?: Record<any, any> | string,
    noContentIsOk: boolean = false
  ): Promise<any> => {
    const config = {
      method: "post",
      url: url,
      baseURL: this.BASE_URL,
      headers: this.getHeaders(),
      ...(data !== undefined && { data: data }),
    };
    try {
      const res = await axios(config);
      console.log('test response',res)
      if (res.statusText === 'OK' || (noContentIsOk && res.statusText === 'No Content')) {
        return res;
      }
      this._configApi.notification.error({
        message: 'Network Error',
        description: <><b>URL: </b>{url}<br /><b>STATUS TEXT: </b>{res.statusText}<br /><b>STATUS: </b>{res.status}</>,
        duration: 10,
      });
      console.log(`url:`, url);
      console.log(`request data:`, data);
      console.log(`response:`, res);
      return undefined;
    } catch (error) {
      this._configApi.notification.error({
        message: 'Network Error',
        description: <><b>URL: </b>{url}<br /></>,
        duration: 10,
      });
      console.log(`url:`, url);
      console.log(`request data:`, data);
      return undefined;
    }
  };
  protected tryTenantPost = async (
    url: string,
    data?: Record<any, any> | string,
    noContentIsOk: boolean = false
  ): Promise<any> => {
    const config = {
      method: "post",
      url: url,
      baseURL:this.BASE_URL,
      headers: this.getHeaders("multipart/form-data"),
      ...(data !== undefined && { data: data }),
    };
    try {
      const res = await axios(config);
      if(res?.data?.status){
        Swal.fire({
          title: "Success",
          text: res?.data?.errorMessage,
          icon: "success",
          confirmButtonColor: "#44637e",
        });
      }
      else{
        Swal.fire({
          title: "Failure",
          text: res?.data?.errorMessage,
          icon: "error",
          confirmButtonColor: "#44637e",
        });
      }
      return res?.data?.status;
    } catch (error) {
      Swal.fire({
        title: "Failure",
        text: "Something went wrong. Please try again or contact us",
        icon: "error",
        confirmButtonColor: "#44637e",
      });
      return false;
    }
  };

  protected tryTenantGet = async (
    url: string,
    data?: Record<any, any> | string,
    noContentIsOk: boolean = false
  ): Promise<any> => {
    const config = {
      method: "get",
      url: url,
      baseURL: this.BASE_URL,//this.BASE_URL,
      headers: this.getHeaders("multipart/form-data"),
      ...(data !== undefined && { data: data }),
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      Swal.fire({
        title: "Failure",
        text: "Something went wrong. Please try again or contact us",
        icon: "error",
        confirmButtonColor: "#44637e",
      });
      return false;
    }
  };
  

    private checkEmail = (str: string): string => {
        const validEmails: string[] = [
        "ven.pasynsoftdev4@associatedasset.com",
        "ven.pasynsoftdev2@associatedasset.com",
        "tmctesterson@aamaz.com",
        ];

        return validEmails.includes(str) ? "tnpatterson@associatedasset.com" : str;
    };


  private getRicHeader = (): Record<string, string> => {
    let randomizing = this.randomString();
    let KEY = this.formatString(this.xApiKey?.replace(/[-:;,._\\@/*&^%$#!()=+]/g, ""), 32, "0");
    let IV = this.formatString(
    `${randomizing}WWRQYHBC2iwkjqq366wfgWQ2qqiWEqD123SDfaAgxk5q1EfBshryYLo${randomizing}edKxjDqw4qqxWKFRf6E${randomizing}ZUC5qweq234JU6ozwUVLao`.replace(
        /[-:;,._\\@/*&^%$#!()=+]/g,
        ""
    ),
    16,
    "0"
    );

    let key = CryptoJS.enc.Utf8.parse(KEY);
    let iv = CryptoJS.enc.Utf8.parse(IV);

    let encrypted;

    let srcs = CryptoJS.enc.Utf8.parse(this.xApiKey);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    });

    let encryptedE;

    let srcsE = CryptoJS.enc.Utf8.parse(this.checkEmail(this._configApi.userEmail));

    encryptedE = CryptoJS.AES.encrypt(srcsE, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    });

    return {
    "public-key": encrypted.ciphertext.toString(),
    "www": IV,
    "Email": encryptedE.ciphertext.toString(),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS",
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Max-Age": "1800",
    "Access-Control-Allow-Credentials": "true",
    };
};

  protected RicGet = async(url:string): Promise<any> =>{
    return await axios({
        method: 'get',
        baseURL: this.BASE_URLRIC,
        url: url,
        headers: this.getRicHeader(),
    });
}



}



export default MobileWebApiClient;
