import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { ComplianceConcern, ComplianceConcernHistoryItem, ComplianceFormType } from "../../../api/Association/AssociationTypes";
import { Attachment } from "../../../api/Common/CommonTypes";
import { getPhoneFormat } from "../../../helpers/phoneFormat";

class ComplianceConcernStore {
  private _api: ApiStore;

  isLoading: boolean = false;
  complianceDisclaimer?: string = undefined;
  isValidateComplianceForm: boolean = false;
  complianceForm: ComplianceFormType = {} as ComplianceFormType;
  attachments: Array<Attachment> = [];

  complianceHistory: ComplianceConcernHistoryItem[] = [];

  constructor(api: ApiStore) {
    makeAutoObservable(this);
    this._api = api;
  }

  getHistory = async () => {
    this.isLoading = true;
    try {
      const response = await this._api.FormsApiClient.getComplianceHistory();
      let localHistoryItems: ComplianceConcernHistoryItem[] = [];
      const history: ComplianceConcernHistoryItem[] = response.taskList?.map((item: ComplianceConcernHistoryItem) => ({
        ...item,
        created: new Date(item.created)
      }))
      localHistoryItems = history;
      this.complianceHistory = localHistoryItems;
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  };

  setValidForm = (isValid: boolean) => {
    this.isValidateComplianceForm = isValid;
  };

  setForm = (formValues: ComplianceFormType) => {
    this.complianceForm = { ...formValues };
  };

  saveForm = async () => {
    this.isLoading = true;
    try {
      const allPromisesAttach = this.attachments?.map(async (item) => {
        const attachGUID =
          await this._api.CommonApiClient.addAttachmentToBuffer(item);
        if (typeof attachGUID === "string") {
          item.Guid = attachGUID;
          item.Data = null as any;
          item.Url = null as any;
        }
      });
      if (allPromisesAttach) {
        await Promise.all(allPromisesAttach);
      }
      await this._api.FormsApiClient.saveComplianceForm({
        ...this.complianceForm,
        Description: "",
        Attachments: this.attachments,
      } as ComplianceConcern);

      await this.resetForm();
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  };

  resetForm = async () => {
    this.attachments = [];
    await this.getComplianceDisclaimer();
    await this.getComplianceFormData();
  };

  getComplianceDisclaimer = async () => {
    this.isLoading = true;
    try {
      const complianceDisclaimerResponsive =
        await this._api.FormsApiClient.getComplianceDisclaimer();
      if (complianceDisclaimerResponsive.item1 === true) {
        this.complianceDisclaimer = complianceDisclaimerResponsive.item2;
      }
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  };

  getComplianceFormData = async () => {
    this.isLoading = true;
    const residentDirectoryProfile = await this._api.FormsApiClient.getResidentDirectoryProfile();
    const newFormData = residentDirectoryProfile as ComplianceFormType;
    this.setForm(newFormData);
    this.isLoading = false;
  };
  
}
export default ComplianceConcernStore;
