import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "antd";
import Component from "../../../components";
import Swal from "sweetalert2";
import Spinner from "../../../components/Spinner/Spiner";
import Checkbox from "antd/es/checkbox/Checkbox";
import DateTimePicker from "../../../components/dateTime/DateTime";
import dayjs from "dayjs";
import moment from "moment";
import { useStore } from "../../../root-store-context";

const Tenant = () => {
  const { apiStore, applicationStore } = useStore();
  const {
    TextInput,
    SelectBox,
    CheckBox,
    UploadButton,
    TextArea,
    DynamicTable,
    Modal,
    Radio,
    DynamicForm,
  } = Component;
  const [allStatesAndCities, setAllStatesAndCities] = useState([]);
  const [tenantState, setTenantState] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [temp, setTemp] = useState({});
  const [isUnder18, setIsUnder18] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState("");
  const [isEmergencyContactModalVisible, setIsEmergencyContactModalVisible] =
    useState(false);
  const [isVehicleModalVisible, setIsVehicleModalVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [isPetModalVisible, setIsPetModalVisible] = useState(false);
  const [isEcModalVisible, setIsEcModalVisible] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantData, setTenantData] = useState([]);
  const [tenantId, setTenantId] = useState(1);
  const [toEditId, setToEditId] = useState(0);
  const [toEditVehicleId, setToEditVehicleId] = useState(0);
  const [toEditPetId, setToEditPetId] = useState(0);
  const [contactPage, setContactPage] = useState("");
  const [vehiclePage, setVehiclePage] = useState("");
  const [petPage, setPetPage] = useState("");
  const [conatcToEditId, setContactToEditId] = useState(0);
  const [petId, setPetId] = useState(1);
  const [contactId, setContactId] = useState(1);
  const [vehicleId, setVehicleId] = useState(1);
  const [vehicleData, setVehicleData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [petData, setPetData] = useState([]);
  const [state, setState] = useState([]);
  const [form] = Form.useForm();
  const [tenantForm] = Form.useForm();
  const [vehicleForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [petForm] = Form.useForm();
  const [maintenance, setMaintenance] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [contact, setContact] = useState({});
  const [pet, setPet] = useState({});
  const [tenant, setTenant] = useState({});
  const [city, setCity] = useState([]);
  const [tenantCity, setTenantCity] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [fee, setFee] = useState("");
  const [stateDisclaimer, setStateDisclaimer] = useState("");
  const [propertyDisclaimer, setPropertyDisclaimer] = useState("");
  const [isLoadingStatesAndCities, setIsLoadingStatesAndCities] =
    useState(true);
  const [isLoadingDisclaimer, setIsLoadingDisclaimer] = useState(true);
  const [isLoadingTenantResponse, setIsLoadingTenantResponse] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState("");
  const [fileList, setFileList] = useState([]);
  const [formatted, setFormatted] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedTenantIndex, setExpandedTenantIndex] = useState(-1);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editedTenant, setEditedTenant] = useState({
    tenantFirstName: "",
    tenantLastName: "",
    tenantAddress1: "",
    tenantState: "",
    tenantCity: "",
    tenantZip: "",
    tenantPhone: "",
    tenantEmail: "",
    violation: false,
    age: false,
  });
  const [newStates, setNewStates] = useState([]);
  const [newCities, setNewCities] = useState([]);
  let modifiedCitys = [];
  const handleEdit = (tenantToEdit) => {
    setToEditId(tenantToEdit.id);
    let tempTenant = {};
    tempTenant["tenantId"] = tenantToEdit.id;
    tempTenant["tenantFirstName"] = tenantToEdit.FirstName;
    tempTenant["tenantLastName"] = tenantToEdit.LastName;
    tempTenant["tenantDesignation"] = tenantToEdit.Designation;
    tempTenant["tenantAddress1"] = tenantToEdit.MailingAddress1;
    tempTenant["tenantState"] = tenantToEdit.State;
    tempTenant["tenantCity"] = tenantToEdit.City;
    tempTenant["tenantZip"] = tenantToEdit.zip;
    tempTenant["tenantPhone"] = tenantToEdit.Phone;
    tempTenant["tenantEmail"] = tenantToEdit.Email;
    tempTenant["violation"] = tenantToEdit.Voilation;
    tempTenant["age"] = tenantToEdit.Under18;
    tempTenant["contactData"] = tenantToEdit.EmergencyContacts;
    setContactData(tenantToEdit.EmergencyContacts);
    setTemp(tempTenant);
    tenantForm.setFieldsValue(tempTenant);
    showModal("edit");
  };
  const handleEditContact = (contactToEdit) => {
    setContactToEditId(contactToEdit.id);
    let tempContact = {};
    tempContact["contactFirstName"] = contactToEdit.FirstName;
    tempContact["contactLastName"] = contactToEdit.LastName;
    tempContact["contactEmail"] = contactToEdit.Email;
    tempContact["contactPhone"] = contactToEdit.Phone;
    contactForm.setFieldsValue(tempContact);
    showContactModal("edit");
  };
  const handleEditVehicle = (vehicleToEdit) => {
    setToEditVehicleId(vehicleToEdit.id);
    let tempVehicle = {};
    tempVehicle["vehicleYear"] = vehicleToEdit.Year;
    tempVehicle["vehicleMake"] = vehicleToEdit.Make;
    tempVehicle["vehicleModel"] = vehicleToEdit.Model;
    tempVehicle["vehicleColor"] = vehicleToEdit.Color;
    tempVehicle["vehicleLicensePlate"] = vehicleToEdit.License;
    tempVehicle["vehicleState"] = vehicleToEdit.State;
    tempVehicle["vehicleSpot"] = vehicleToEdit.Spot;
    vehicleForm.setFieldsValue(tempVehicle);
    showVehicleModal("edit");
  };
  const handleEditPet = (petToEdit) => {
    setToEditPetId(petToEdit.id);
    let tempPet = {};
    tempPet["petName"] = petToEdit.Name;
    tempPet["petType"] = petToEdit.Type;
    tempPet["petBreed"] = petToEdit.Breed;
    tempPet["petDueDate"] = dayjs(dueDate, "MM/DD/YYYY"); //petToEdit.RabiesDueDate;
    tempPet["petLicense"] = petToEdit.License;
    tempPet["petAge"] = petToEdit.Age;
    petForm.setFieldsValue(tempPet);
    showPetModal("edit");
  };

  const tenantRes = ""; //useSelector((state) => state?.CreateTenant);
  let states;
  const showModal = (mode) => {
    setIsModalVisible(true);
    setPage(mode);
  };
  const showVehicleModal = (mode) => {
    setIsVehicleModalVisible(true);
    setVehiclePage(mode);
  };
  const showContactModal = (mode) => {
    setIsContactModalVisible(true);
    setContactPage(mode);
  };
  const showPetModal = (mode) => {
    setIsPetModalVisible(true);
    setPetPage(mode);
  };
  const openEmergencyContactModal = () => {
    setIsEmergencyContactModalVisible(true);
  };
  const handlePetDelete = (id) => {
    const updatedData = petData.filter((pet) => pet.id !== id);
    setPetData(updatedData);
  };
  function handleTenantDelete(id) {
    const updatedData = tenantData.filter((tenant) => tenant.id !== id);
    setTenantData(updatedData);
  }
  const handleContactDelete = (id) => {
    const updatedData = contactData.filter((contact) => contact.id !== id);
    setContactData(updatedData);
  };
  const handleVehicleDelete = (id) => {
    const updatedData = vehicleData.filter((vehicle) => vehicle.id !== id);
    setVehicleData(updatedData);
  };
  const closeEmergencyContactModal = () => {
    setIsEmergencyContactModalVisible(false);
  };
  const handleCancel = () => {
    tenantForm.resetFields();
    setIsModalVisible(false);
  };
  const handleVehicleCancel = () => {
    vehicleForm.resetFields();
    setIsVehicleModalVisible(false);
  };
  const handleContactCancel = () => {
    contactForm.resetFields();
    setIsContactModalVisible(false);
  };
  const handlePetCancel = () => {
    petForm.resetFields();
    setIsPetModalVisible(false);
  };

  const handleFormSubmit = (values) => {
    if (!isContactModalVisible) {
      const newTenant = {
        id: tenantId,
        FirstName: tenant.tenantFirstName,
        LastName: tenant.tenantLastName,
        Designation: tenant.age ? tenant.tenantDesignation : "",
        MailingAddress1: tenant.tenantAddress1,
        State: tenantState,
        City: tenant.tenantCity,
        zip: tenant.tenantZip,
        Phone: tenant.tenantPhone,
        Email: tenant.tenantEmail,
        Voilation: tenant.violation == true ? true : false,
        Under18: tenant.age == true ? true : false,
        EmergencyContacts: contactData,
      };
      if (page === "edit") {
        newTenant.id = toEditId;
        const updatedTenants = tenantData.map((t) =>
          t.id === newTenant.id ? newTenant : t
        );
        setTenantData(updatedTenants);
      } else {
        setTenantId(tenantId + 1);
        setTenantData([...tenantData, newTenant]);
      }
      setContactData([]);
      tenantForm.resetFields();
      setIsModalVisible(false);
    }
  };
  const handleVehicleFormSubmit = (values) => {
    const newVehicle = {
      id: vehicleId,
      Year: vehicle.vehicleYear,
      Make: vehicle.vehicleMake,
      Model: vehicle.vehicleModel,
      Color: vehicle.vehicleColor,
      License: vehicle.vehicleLicensePlate,
      State: vehicle.vehicleState,
      Spot: vehicle.vehicleSpot,
    };
    if (vehiclePage === "edit") {
      newVehicle.id = toEditVehicleId;
      const updatedVehicles = vehicleData.map((v) =>
        v.id === newVehicle.id ? newVehicle : v
      );
      setVehicleData(updatedVehicles);
    } else {
      setVehicleId(vehicleId + 1);
      setVehicleData([...vehicleData, newVehicle]);
    }

    vehicleForm.resetFields();
    setIsVehicleModalVisible(false);
  };
  const handleContactFormSubmit = (values) => {
    const newContact = {
      id: contactId,
      FirstName: contact.contactFirstName,
      LastName: contact.contactLastName,
      Email: contact.contactEmail,
      Phone: contact.contactPhone,
    };

    if (contactPage === "edit") {
      newContact.id = conatcToEditId;
      const updatedContacts = contactData.map((c) =>
        c.id === newContact.id ? newContact : c
      );
      setContactData(updatedContacts);
    } else {
      setContactId(contactId + 1);
      setContactData([...contactData, newContact]);
    }

    contactForm.resetFields();
    setIsContactModalVisible(false);
  };
  const handlePetFormSubmit = (values) => {
    const newPet = {
      id: petId,
      Name: pet.petName,
      Type: pet.petType,
      Breed: pet.petBreed,
      RabiesDueDate: dueDate,
      License: pet.petLicense,
      Age: pet.petAge,
    };
    if (petPage === "edit") {
      newPet.id = toEditPetId;
      const updatedPets = petData.map((p) => (p.id === newPet.id ? newPet : p));
      setPetData(updatedPets);
    } else {
      setPetId(petId + 1);
      setPetData([...petData, newPet]);
    }

    petForm.resetFields();
    setIsPetModalVisible(false);
  };
  const handleViewContacts = (tenant) => {
    setSelectedTenant(tenant);
    setIsEcModalVisible(true);
  };

  const handleModalOk = () => {
    setIsEcModalVisible(false);
    setSelectedTenant(null);
  };

  const handleModalCancel = () => {
    setIsEcModalVisible(false);
    setSelectedTenant(null);
  };
  const options = [
    {
      value: true,
      name: "New",
    },
    {
      value: false,
      name: "Renew",
    },
  ];
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleChange = ({ fileList: newFileList }) => {
    let fileNames = [];
    let i = 0;
    newFileList.forEach((file) => {
      if (i == 0) {
        fileNames = [...fileNames, file.name];
        i = i + 1;
      } else {
        if (fileNames.includes(file.name)) {
          Swal.fire({
            title: "Warning",
            text: "File name already exists.(" + file.name + ")",
            icon: "warning",
            confirmButtonColor: "#44637e",
          });
          newFileList.splice(newFileList.indexOf(file), 1);
        } else {
          fileNames = [...fileNames, file.name];
        }
      }
      setFileList(fileNames);
    });
    const maxSize = 5 * 1024 * 1024 * 1024;
    let totalSize = 0;
    let filesChecked = [];
    newFileList.forEach((file) => {
      totalSize = totalSize + file?.originFileObj?.size;
      if (totalSize > maxSize) {
        Swal.fire({
          title: "Warning",
          text: "Can not upload files of size more than 5 GB.",
          icon: "warning",
          confirmButtonColor: "#44637e",
        });
      } else {
        filesChecked.push(file);
      }
    });
    setFileList(filesChecked);
  };

  const onFinish = async (values) => {
    if (
      !isContactModalVisible &&
      !isVehicleModalVisible &&
      !isPetModalVisible &&
      !isModalVisible
    ) {
      if (tenantData.length == 0) {
        Swal.fire({
          text: "Please provide atleast one tenant info.",
          icon: "warning",
          confirmButtonColor: "#5A7890",
          customClass: {
            title: "text-danger", // Custom class for the title text
            content: "text-danger", // Custom class for the content text
          },
        });
        return;
      }
      if (!tenantData.some((tenant) => tenant.Under18 == false)) {
        Swal.fire({
          text: "Please provide atleast one tenant who is not under 18.",
          icon: "warning",
          confirmButtonColor: "#5A7890",
          customClass: {
            title: "text-danger", // Custom class for the title text
            content: "text-danger", // Custom class for the content text
          },
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append(
          `leaseInfo.newOrRenew`,
          form.getFieldValue("newOrRenew") ? "New" : "Renew"
        );
        formData.append(`leaseInfo.startDay`, startDate);
        formData.append(`leaseInfo.endDay`, endDate);
        formData.append(`cardNumber`, form.getFieldValue("cardNumber")?.trim());
        formData.append(`cvv`, form.getFieldValue("cvv"));
        formData.append(`cardExpiry`, form.getFieldValue("cardExpiry"));
        formData.append(`cardHolderName`, form.getFieldValue("cardHolderName"));
        formData.append(`amount`, fee);
        formData.append(
          `residentKey`,
          applicationStore?.SelectedAddress?.residentKey?.toString()
        );
        formData.append(
          `userId`,
          applicationStore?.SelectedAddress?.userId?.toString()
        );

        fileList.forEach((file, index) => {
          formData.append(`supportingDocuments`, file.originFileObj, file.name); // Assuming file.data is the Blob or File object
        });
        tenantData.forEach((obj, index) => {
          formData.append(`tenants[${index}].firstName`, obj.FirstName);
          formData.append(`tenants[${index}].lastName`, obj.LastName);
          formData.append(`tenants[${index}].designation`, obj.Designation);
          formData.append(
            `tenants[${index}].mailingAddress1`,
            obj.MailingAddress1
          );
          formData.append(`tenants[${index}].state`, obj.State);
          formData.append(`tenants[${index}].city`, obj.City);
          formData.append(`tenants[${index}].zip`, obj.zip);
          formData.append(`tenants[${index}].phone`, obj.Phone);
          formData.append(`tenants[${index}].email`, obj.Email);
          formData.append(
            `tenants[${index}].voilation`,
            obj.Voilation ? "true" : "false"
          );
          formData.append(
            `tenants[${index}].under18`,
            obj.Under18 ? "true" : "false"
          );
          obj.EmergencyContacts.forEach((contact, i) => {
            formData.append(
              `tenants[${index}].emergencyContacts[${i}].firstName`,
              contact.FirstName
            );
            formData.append(
              `tenants[${index}].emergencyContacts[${i}].lastName`,
              contact.LastName
            );
            formData.append(
              `tenants[${index}].emergencyContacts[${i}].email`,
              contact.Email
            );
            formData.append(
              `tenants[${index}].emergencyContacts[${i}].phone`,
              contact.Phone
            );
          });
        });
        vehicleData.forEach((obj, index) => {
          formData.append(`vehicles[${index}].make`, obj.Make);
          formData.append(`vehicles[${index}].model`, obj.Model);
          formData.append(`vehicles[${index}].year`, obj.Year);
          formData.append(`vehicles[${index}].color`, obj.Color);
          formData.append(`vehicles[${index}].license`, obj.License);
          formData.append(`vehicles[${index}].state`, obj.State);
          formData.append(`vehicles[${index}].spot`, obj.Spot);
        });
        petData.forEach((obj, index) => {
          formData.append(`pets[${index}].name`, obj.Name);
          formData.append(`pets[${index}].type`, obj.Type);
          formData.append(`pets[${index}].breed`, obj.Breed);
          formData.append(`pets[${index}].rabiesDueDate`, obj.RabiesDueDate);
          formData.append(`pets[${index}].license`, obj.License);
          formData.append(`pets[${index}].age`, obj.Age);
        });

        if (formData) {
          console.log(formData);
          setIsLoadingTenantResponse(true);
          const status =
            await apiStore.FormsApiClient.PostTenantRegistrationForms(formData);
          setIsLoadingTenantResponse(false);

          if (status) {
            handleClearButton();
          }
        }
      } catch (error) {
        Swal.fire({
          text: "Submission unsuccessfull. Please try again or contact us.",
          icon: "error",
          confirmButtonColor: "#5A7890",
          customClass: {
            title: "text-danger", // Custom class for the title text
            content: "text-danger", // Custom class for the content text
          },
        });
      }
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const HandleTenantOnChange = async (e) => {
    debugger;
    const { name, id, value } = e?.target;
    let updatedTenant;
    if (name == "violation" || name == "age") {
      if (name == "age") {
        setIsUnder18(e.target.checked);
      }
      updatedTenant = { ...tenant, [name]: e.target.checked };
    } else {
      updatedTenant = { ...tenant, [id]: value };
    }

    if (id === "tenantState" && modifiedCitys) {
      setTenantState(value);
      const cities = newCities?.[value];
      updatedTenant = { ...tenant, ["tenantCity"]: null };
      let newC = [];
      if (cities?.length > 0) {
        for (let items of cities) {
          newC.push({ name: items, value: items });
        }
      }
      setTenantCity(newC);
    }
    setTenant(updatedTenant);
    tenantForm.setFieldsValue(updatedTenant);
  };
  const HandleVehicleOnChange = async (e) => {
    const { id, value } = e?.target;
    const updatedVehicle = { ...vehicle, [id]: value };
    setVehicle(updatedVehicle);
    vehicleForm.setFieldsValue(updatedVehicle);
  };
  const HandleContactOnChange = async (e) => {
    const { id, value } = e?.target;
    const updatedContact = { ...contact, [id]: value };
    setContact(updatedContact);
    contactForm.setFieldsValue(updatedContact);
  };
  const HandlePetOnChange = async (e) => {
    const { id, value } = e?.target;
    const updatedPet = { ...pet, [id]: value };
    setPet(updatedPet);
    petForm.setFieldsValue(updatedPet);
  };

  const handleCardNumberChange = (e) => {
    const { value } = e.target;

    // Remove all non-digit characters
    const cleaned = value.replace(/\D+/g, "");

    // Limit to 16 digits
    const limited = cleaned.slice(0, 16);

    // Split the cleaned number into chunks of 4 digits and join with spaces
    const formattedCard = limited.match(/.{1,4}/g)?.join(" ") || "";

    // Update the formatted value
    const formatted = { cardNumber: formattedCard };

    // Update local state or form field value
    setFormatted(formatted);
    form.setFieldsValue(formatted);
  };
  useEffect(() => {
    if (states?.length > 0) {
      const newState = states.map((item) => ({
        name: item,
        value: item,
      }));
      setState(newState);
    }
  }, [states]);
  const dateFormatRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  const expiryDateFormatRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
  function isValidDate(dateStr) {
    const [month, day, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    return (
      date.getMonth() === month - 1 &&
      date.getDate() === day &&
      date.getFullYear() === year
    );
  }
  const validateExpiryDate = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    // Check if value is in "MM/YY" format
    const formatRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!formatRegex.test(value)) {
      return Promise.reject(
        new Error("Expiry Date must be in the format MM/YY")
      );
    }

    // Extract month and year from the value
    const month = parseInt(value.slice(0, 2), 10);
    const year = parseInt(value.slice(3, 5), 10);

    // Get current date
    const now = moment();
    const currentYear = now.year();
    const currentMonth = now.month() + 1; // Months are 0-based in moment

    // Determine the full year based on the two-digit year
    const fullYear = year >= currentYear % 100 ? 2000 + year : 1900 + year;

    // Check if the expiry date is valid
    if (month < 1 || month > 12) {
      return Promise.reject(
        new Error("Expiry Date month must be between 01 and 12")
      );
    }

    const expiryDate = moment()
      .set({ year: fullYear, month: month - 1, date: 1 })
      .endOf("month");

    // Check if the expiry date is in the future
    if (expiryDate.isBefore(now, "month")) {
      return Promise.reject(new Error("Expiry Date must be in the future"));
    }

    return Promise.resolve();
  };

  const validateCVVNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    if (!/^\d{3}$/.test(value) && !/^\d{4}$/.test(value)) {
      return Promise.reject(new Error("CVV can be either 3 or 4 digits long."));
    }

    return Promise.resolve();
  };
  const validateCardNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    // Remove spaces
    const cleanedNumber = value?.replace(/\s+/g, "");

    // Check if the cleaned number has exactly 16 digits
    if (!/^\d{16}$/.test(cleanedNumber)) {
      return Promise.reject(
        new Error("Credit Card Number must be exactly 16 digits")
      );
    }

    return Promise.resolve();
  };
  const validationRules = {
    firstName: [
      { required: true, message: "First Name is required" },
      { max: 100, message: "First Name cannot be longer than 100 characters" },
      {
        min: 1,
        message: "First Name cannot be empty",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "First Name must contain only letters and spaces",
      },
    ],
    lastName: [
      { required: true, message: "Last Name is required" },
      { max: 100, message: "Last Name cannot be longer than 100 characters" },
      {
        min: 1,
        message: "Last Name cannot be empty",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Last Name must contain only letters and spaces",
      },
    ],
    address: [
      { required: true, message: "Address is required" },
      { max: 2000, message: "Address cannot be longer than 2000 characters" },
      {
        min: 1,
        message: "Address cannot be empty",
      },
    ],
    state: [{ required: true, message: "State is required" }],
    city: [{ required: true, message: "City is required" }],
    property: [{ required: true, message: "City is required" }],
    zip: [
      { required: true, message: "Zip code is required" },
      {
        pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
        message: "Valid Zip code formats are: 12345 and 12345-6789",
      },
    ],
    phone: [
      { required: true, message: "Phone number is required" },
      { pattern: /^[0-9]{10}$/, message: "Phone number must be 10 digits" },
    ],
    email: [
      { required: true, message: "Email is required" },
      { type: "email", message: "Invalid email format" },
      { max: 180, message: "Address cannot be longer than 180 characters" },
    ],
    designation: [
      { required: true, message: "Designation is required for those under 18" },
      { max: 180, message: "Designation cannot be longer than 180 characters" },
      {
        min: 1,
        message: "Address cannot be empty",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Designation must contain only letters and spaces",
      },
    ],
    date: [
      {
        required: true,
        message: "Date is required",
      },
    ],
    year: [
      {
        required: true,
        message: "Year is required",
      },
      {
        pattern: /^[0-9]{4}$/,
        message: "Year must be a 4-digit number",
      },
      {
        validator: (_, value) => {
          const currentYear = new Date().getFullYear();
          if (!value || (value >= 1900 && value <= currentYear)) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(`Year must be between 1900 and ${currentYear}`)
          );
        },
      },
    ],
    make: [
      {
        required: true,
        message: "Make is required",
      },
      {
        min: 1,
        message: "Make cannot be empty",
      },
      {
        max: 180,
        message: "Make cannot be more than 180 characters.",
      },
    ],
    model: [
      {
        required: true,
        message: "Model is required",
      },
      {
        min: 1,
        message: "Model cannot be empty",
      },
      {
        max: 180,
        message: "Model cannot be more than 180 characters.",
      },
    ],
    color: [
      {
        required: true,
        message: "Color is required",
      },
      {
        min: 1,
        message: "Color cannot be empty",
      },
      {
        max: 60,
        message: "Color cannot be more than 60 characters.",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Color must contain only letters and spaces",
      },
    ],
    license: [
      {
        required: true,
        message: "License is required",
      },
      {
        min: 1,
        message: "License cannot be empty",
      },
      {
        max: 150,
        message: "License cannot be more than 150 characters.",
      },
    ],
    spot: [
      {
        required: true,
        message: "Spot / Garage is required",
      },
      {
        min: 1,
        message: "Spot / Garage cannot be empty",
      },
      {
        max: 180,
        message: "Spot / Garage cannot be more than 180 characters.",
      },
    ],
    name: [
      { required: true, message: "Name is required" },
      { max: 180, message: "Name cannot be longer than 180 characters" },
      {
        min: 1,
        message: "Name cannot be empty",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Name must contain only letters and spaces",
      },
    ],
    type: [
      { required: true, message: "Type is required" },
      { max: 180, message: "Type cannot be longer than 180 characters" },
      {
        min: 1,
        message: "Type cannot be empty",
      },
    ],
    breed: [
      { required: true, message: "Breed is required" },
      { max: 140, message: "Breed cannot be longer than 140 characters" },
      {
        min: 1,
        message: "Breed cannot be empty",
      },
    ],
    age: [
      {
        required: true,
        message: "Age is required",
      },
      { pattern: /^\d*$/, message: "Age must be in digit(s)." },
    ],
    newOrRenew: [
      {
        required: true,
        message: "Type of Lease is required.",
      },
    ],
    cardNumber: [
      {
        required: true,
        message: "Credit Card Number is required",
      },
      { validator: validateCardNumber },
    ],
    cvv: [
      {
        required: true,
        message: "CVV is required",
      },
      { validator: validateCVVNumber },
    ],
    cardExpiry: [
      {
        required: true,
        message: "Expiry Date is required",
      },
      { validator: validateExpiryDate },
    ],
    cardHolderName: [
      {
        required: true,
        message: "Card Holder Name is required",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Card Holder Name must contain only letters and spaces",
      },
    ],
  };
  const handleDateChange = (date, dateString) => {
    setDueDate(dateString); // Update state on date change
  };
  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString); // Update state on date change
  };
  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString); // Update state on date change
  };
  function handleClearButton() {
    form.resetFields(); // Resets all form fields
    petForm.resetFields(); // Resets all form fields
    tenantForm.resetFields(); // Resets all form fields
    contactForm.resetFields(); // Resets all form fields
    vehicleForm.resetFields(); // Resets all form fields
    setCity([]); // Clears city options
    setCommunities([]); // Clears communities options
    setTenantData([]); // Clears communities options
    setContactData([]); // Clears communities options
    setPetData([]); // Clears communities options
    setVehicleData([]); // Clears communities options
    setFileList([]); // Clears communities options
    setDueDate(null); // Clears communities options
    setStartDate(null); // Clears communities options
    setEndDate(null); // Clears communities options
  }
  console.log(petForm?.getFieldsValue(), "sdafdsjfsdafsdafsdafa");
  useEffect(() => {
    setIsLoadingStatesAndCities(true);

    apiStore.FormsApiClient.GetAllStatesCitiesAndCommunities().then((res) => {
      setAllStatesAndCities(res?.data);
      states =
        res?.data["item1"] &&
        Object.keys(res?.data["item1"])?.length > 0 &&
        Object.entries(res?.data["item1"]).map(([key, value]) => key);

      let newSt = [];
      states &&
        states.forEach((element) => {
          element = element.substring(1, element.length - 1);
          const name = element.split(",")[0].trim();
          let value = element.split(",")[1].trim();
          newSt.push({ name: name, value: value });
        });
      setNewStates(newSt);
      let citys = res?.data["item1"];
      modifiedCitys =
        citys &&
        Object.entries(citys).reduce((acc, [key, value]) => {
          key = key.substring(1, key.length - 1);
          const newKey = key.split(",")[1].trim();
          acc[newKey] = value;
          return acc;
        }, {});
      setNewCities(modifiedCitys);
      console.log(newStates, "MY STATES");
    });
    setIsLoadingStatesAndCities(false);
  }, []);

  useEffect(() => {
    setIsLoadingDisclaimer(true);

    apiStore.FormsApiClient.GetDisclaimersAndFee(
      applicationStore?.SelectedAddress?.residentKey
    ).then((res) => {
      setPropertyDisclaimer(res?.data["item1"]);
      setStateDisclaimer(res?.data["item2"]);
      setFee(res?.data["item3"]);

      setIsLoadingDisclaimer(false);
    });
  }, [applicationStore?.SelectedAddress?.residentKey]);

  const columns = [
    { title: "FirstName", dataIndex: "FirstName", key: "FirstName" },
    { title: "LastName", dataIndex: "LastName", key: "LastName" },
    { title: "Designation", dataIndex: "Designation", key: "Designation" },
    {
      title: "MailingAddress1",
      dataIndex: "MailingAddress1",
      key: "MailingAddress1",
    },
    { title: "State", dataIndex: "State", key: "State" },
    { title: "City", dataIndex: "City", key: "City" },
    { title: "zip", dataIndex: "zip", key: "zip" },
    { title: "Phone", dataIndex: "Phone", key: "Phone" },
    { title: "Email", dataIndex: "Email", key: "Email" },
    {
      title: "Voilation",
      dataIndex: "Voilation",
      key: "Voilation",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Under18",
      dataIndex: "Under18",
      key: "Under18",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Emergency Contacts",
      dataIndex: "emergencyContacts",
      key: "emergencyContacts",
      render: (emergencyContacts, record) => (
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #5A7890",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
          }}
          onClick={() => handleViewContacts(record)}
        >
          View Contacts
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
              }}
              onClick={() => handleEdit(record)}
            >
              Edit
            </Button>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
                marginTop: 5,
              }}
              onClick={() => handleTenantDelete(record.id)}
            >
              Delete
            </Button>
          </>
        </div>
      ),
    },
  ];
  const vehicleColumns = [
    { title: "Year", dataIndex: "Year", key: "Year" },
    { title: "Make", dataIndex: "Make", key: "Make" },
    { title: "Model", dataIndex: "Model", key: "Model" },
    { title: "Color", dataIndex: "Color", key: "Color" },
    {
      title: "License Plate",
      dataIndex: "License",
      key: "License",
    },
    { title: "State", dataIndex: "State", key: "State" },
    { title: "Spot", dataIndex: "Spot", key: "Spot" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
              }}
              onClick={() => handleEditVehicle(record)}
            >
              Edit
            </Button>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
                marginTop: 5,
              }}
              onClick={() => handleVehicleDelete(record.id)}
            >
              Delete
            </Button>
          </>
        </div>
      ),
    },
  ];
  const petColumns = [
    { title: "Name", dataIndex: "Name", key: "Name" },
    { title: "Type", dataIndex: "Type", key: "Type" },
    { title: "Breed", dataIndex: "Breed", key: "Breed" },
    {
      title: "Rabies Due Date",
      dataIndex: "RabiesDueDate",
      key: "RabiesDueDate",
    },
    { title: "License", dataIndex: "License", key: "License" },
    { title: "Age", dataIndex: "Age", key: "Age" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
              }}
              onClick={() => handleEditPet(record)}
            >
              Edit
            </Button>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
                marginTop: 5,
              }}
              onClick={() => handlePetDelete(record.id)}
            >
              Delete
            </Button>
          </>
        </div>
      ),
    },
  ];
  const contactColumns = [
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
    },
    { title: "Email", dataIndex: "Email", key: "Email" },
    { title: "Phone", dataIndex: "Phone", key: "Phone" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
              }}
              onClick={() => handleEditContact(record)}
            >
              Edit
            </Button>
            <Button
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2rem",
                width: "7rem",
                marginTop: 5,
              }}
              onClick={() => handleContactDelete(record.id)}
            >
              Delete
            </Button>
          </>
        </div>
      ),
    },
  ];

  return (
    <>
      {(isLoadingStatesAndCities ||
        isLoadingDisclaimer ||
        isLoadingTenantResponse) && <Spinner />}
      <Row justify={"center"}>
        <Col sm={20} className="text-center">
          <div className="text-center mt-5">
            <h1 className="heading-maintenance" style={{ color: "#5A7890" }}>
              Tenant Registration
            </h1>
          </div>
        </Col>

        <Col sm={20}>
          <Form
            style={{ marginBottom: "12rem" }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <>
              <section>
                <Button
                  style={{
                    borderRadius: 19,
                    border: "1px solid #5A7890",
                    color: "#5A7890",
                    backgroundColor: "#fff",
                    height: "2.5rem",
                    width: "8rem",
                    marginBottom: "2rem",
                    marginTop: "3rem",
                  }}
                  onClick={() => {
                    showModal("add");
                  }}
                >
                  Add Tenant
                </Button>
                <Modal
                  title="Add Tenant"
                  centered
                  footer=""
                  open={isModalVisible}
                  Ok={handleFormSubmit}
                  Cancel={handleCancel}
                  width={900} // Adjust width as per your design
                >
                  {(pageName) => (
                    <Form form={tenantForm} onFinish={handleFormSubmit}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.firstName}
                            name="tenantFirstName"
                            size="large"
                            label="First Name"
                            placeholder="Enter First Name"
                            onChange={(e) => HandleTenantOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.lastName}
                            name="tenantLastName"
                            size="large"
                            label="Last Name"
                            placeholder="Enter Last Name"
                            onChange={(e) => HandleTenantOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <p style={{ marginBottom: "15px", fontWeight: "bold" }}>
                          Mailing Addresses
                        </p>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.address}
                            name="tenantAddress1"
                            size="large"
                            label="Address 1"
                            placeholder="Enter Address 1"
                            onChange={(e) => HandleTenantOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <SelectBox
                            label="State"
                            name="tenantState"
                            options={newStates}
                            className="custom-scrollbar"
                            placeholder="Select State"
                            onChange={(e) => HandleTenantOnChange(e)}
                            size="large"
                            rules={validationRules.state}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <SelectBox
                            label="City"
                            name="tenantCity"
                            options={tenantCity}
                            className="custom-scrollbar"
                            placeholder="Select City"
                            onChange={(e) => HandleTenantOnChange(e)}
                            size="large"
                            rules={validationRules.city}
                          />
                        </Col>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.zip}
                            name="tenantZip"
                            size="large"
                            label="Zip"
                            placeholder="Enter Zip"
                            onChange={(e) => HandleTenantOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.phone}
                            name="tenantPhone"
                            size="large"
                            label="Phone"
                            placeholder="Enter Phone"
                            onChange={(e) => HandleTenantOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.email}
                            name="tenantEmail"
                            size="large"
                            label="Email"
                            placeholder="Enter Email"
                            onChange={(e) => HandleTenantOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Checkbox
                            name="violation"
                            value={true}
                            style={{
                              lineHeight: "32px",
                            }}
                            onChange={(e) => HandleTenantOnChange(e)}
                          >
                            Should get Violation Letters?
                          </Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            name="age"
                            value={true}
                            style={{
                              lineHeight: "32px",
                            }}
                            onChange={(e) => HandleTenantOnChange(e)}
                          >
                            Under 18
                          </Checkbox>
                        </Col>
                        {isUnder18 && ( // Render TextInput only if Under 18 checkbox is checked
                          <Col span={8}>
                            <TextInput
                              rules={validationRules.designation}
                              name="tenantDesignation"
                              size="large"
                              label="Designation"
                              placeholder="Enter Designation"
                              onChange={(e) => HandleTenantOnChange(e)}
                            />
                          </Col>
                        )}
                      </Row>
                      <p
                        className="text-center"
                        style={{
                          color: "#5A7890",
                          fontSize: "18px",
                          marginBottom: "2rem",
                          marginTop: "3rem",
                          fontWeight: "bold",
                        }}
                      >
                        Emergency Contact
                      </p>
                      <Button
                        style={{
                          borderRadius: 19,
                          border: "1px solid #5A7890",
                          color: "#5A7890",
                          backgroundColor: "#fff",
                          height: "2.5rem",
                          width: "8rem",
                          marginBottom: "2rem",
                        }}
                        onClick={() => {
                          showContactModal("add");
                        }}
                      >
                        Add Contact
                      </Button>
                      <Modal
                        title="Add Contact"
                        centered
                        footer=""
                        open={isContactModalVisible}
                        Ok={handleContactFormSubmit}
                        Cancel={handleContactCancel}
                        width={900} // Adjust width as per your design
                      >
                        {(pageName) => (
                          <Form
                            form={contactForm}
                            onFinish={handleContactFormSubmit}
                          >
                            <Row gutter={16}>
                              <Col span={12}>
                                <TextInput
                                  rules={validationRules.firstName}
                                  name="contactFirstName"
                                  size="large"
                                  label="First Name"
                                  placeholder="Enter First Name"
                                  onChange={(e) => HandleContactOnChange(e)}
                                />
                              </Col>
                              <Col span={12}>
                                <TextInput
                                  rules={validationRules.lastName}
                                  name="contactLastName"
                                  size="large"
                                  label="Last Name"
                                  placeholder="Enter Last Name"
                                  onChange={(e) => HandleContactOnChange(e)}
                                />
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <TextInput
                                  rules={validationRules.email}
                                  name="contactEmail"
                                  size="large"
                                  label="Email"
                                  placeholder="Enter Email"
                                  onChange={(e) => HandleContactOnChange(e)}
                                />
                              </Col>
                              <Col span={12}>
                                <TextInput
                                  rules={validationRules.phone}
                                  name="contactPhone"
                                  size="large"
                                  label="Phone"
                                  placeholder="Enter Phone"
                                  onChange={(e) => HandleContactOnChange(e)}
                                />
                              </Col>
                            </Row>
                            <Button
                              htmlType="submit"
                              class="submit-button"
                              style={{
                                marginLeft: 8,
                                borderRadius: 19,
                                color: "white",
                                backgroundColor: "#5A7890",
                                height: "2.5rem",
                                width: "8rem",
                              }}
                            >
                              Add
                            </Button>
                          </Form>
                        )}
                      </Modal>
                      <DynamicTable
                        columns={contactColumns}
                        data={contactData}
                      />
                      <Button
                        htmlType="submit"
                        class="submit-button"
                        style={{
                          marginLeft: 8,
                          borderRadius: 19,
                          color: "white",
                          backgroundColor: "#5A7890",
                          height: "2.5rem",
                          width: "8rem",
                        }}
                      >
                        Submit
                      </Button>
                    </Form>
                  )}
                </Modal>

                <DynamicTable columns={columns} data={tenantData} />
                {selectedTenant && (
                  <Modal
                    title="Emergency Contacts"
                    centered
                    open={isEcModalVisible}
                    Ok={handleModalOk}
                    Cancel={handleModalCancel}
                    width={800}
                    footer={[
                      <Button key="back" onClick={handleModalCancel}>
                        Close
                      </Button>,
                    ]}
                    children={(pageName) => (
                      <DynamicTable
                        columns={[
                          { title: "Name", dataIndex: "name", key: "name" },
                          {
                            title: "Email",
                            dataIndex: "email",
                            key: "email",
                          },
                          {
                            title: "Phone",
                            dataIndex: "phone",
                            key: "phone",
                          },
                        ]}
                        data={selectedTenant.EmergencyContacts.map(
                          (contact) => ({
                            key: contact.email,
                            name: `${contact.FirstName} ${contact.FirstName}`,
                            email: contact.Email,
                            phone: contact.Phone,
                          })
                        )}
                      />
                    )}
                  />
                )}
              </section>
              <section>
                <p
                  className="text-center"
                  style={{
                    color: "#5A7890",
                    fontSize: "22px",
                    marginBottom: "4.5rem",
                    marginTop: "4rem",
                    fontWeight: "bold",
                  }}
                >
                  Vehicle Details
                </p>
                <Button
                  style={{
                    borderRadius: 19,
                    border: "1px solid #5A7890",
                    color: "#5A7890",
                    backgroundColor: "#fff",
                    height: "2.5rem",
                    width: "8rem",
                    marginBottom: "2rem",
                  }}
                  onClick={() => {
                    showVehicleModal("add");
                  }}
                >
                  Add Vehicle
                </Button>
                <Modal
                  title="Add Vehicle"
                  centered
                  footer=""
                  open={isVehicleModalVisible}
                  Ok={handleVehicleFormSubmit}
                  Cancel={handleVehicleCancel}
                  width={900} // Adjust width as per your design
                >
                  {(pageName) => (
                    <Form form={vehicleForm} onFinish={handleVehicleFormSubmit}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.year}
                            name="vehicleYear"
                            size="large"
                            label="Year"
                            placeholder="Enter Year"
                            onChange={(e) => HandleVehicleOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.make}
                            name="vehicleMake"
                            size="large"
                            label="Make"
                            placeholder="Enter Make"
                            onChange={(e) => HandleVehicleOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.model}
                            name="vehicleModel"
                            size="large"
                            label="Model"
                            placeholder="Enter Model"
                            onChange={(e) => HandleVehicleOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.color}
                            name="vehicleColor"
                            size="large"
                            label="Color"
                            placeholder="Enter Color"
                            onChange={(e) => HandleVehicleOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.license}
                            name="vehicleLicensePlate"
                            size="large"
                            label="License Plate"
                            placeholder="Enter License Plate"
                            onChange={(e) => HandleVehicleOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <SelectBox
                            options={newStates}
                            rules={validationRules.state}
                            name="vehicleState"
                            size="large"
                            label="State"
                            placeholder="Enter State"
                            onChange={(e) => HandleVehicleOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.spot}
                            name="vehicleSpot"
                            size="large"
                            label="Spot / Garage"
                            placeholder="Enter Spot / Garage"
                            onChange={(e) => HandleVehicleOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Button
                        htmlType="submit"
                        class="submit-button"
                        style={{
                          marginLeft: 8,
                          borderRadius: 19,
                          color: "white",
                          backgroundColor: "#5A7890",
                          height: "2.5rem",
                          width: "8rem",
                        }}
                      >
                        Submit
                      </Button>
                    </Form>
                  )}
                </Modal>
                <DynamicTable columns={vehicleColumns} data={vehicleData} />
              </section>
              <section>
                <p
                  className="text-center"
                  style={{
                    color: "#5A7890",
                    fontSize: "22px",
                    marginBottom: "4.5rem",
                    marginTop: "4rem",
                    fontWeight: "bold",
                  }}
                >
                  Pets Info
                </p>
                <Button
                  style={{
                    borderRadius: 19,
                    border: "1px solid #5A7890",
                    color: "#5A7890",
                    backgroundColor: "#fff",
                    height: "2.5rem",
                    width: "8rem",
                    marginBottom: "2rem",
                  }}
                  onClick={() => {
                    showPetModal("add");
                  }}
                >
                  Add Pet
                </Button>
                <Modal
                  title="Add Pet"
                  centered
                  footer=""
                  open={isPetModalVisible}
                  Ok={handlePetFormSubmit}
                  Cancel={handlePetCancel}
                  width={900} // Adjust width as per your design
                >
                  {(pageName) => (
                    <Form form={petForm} onFinish={handlePetFormSubmit}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.name}
                            name="petName"
                            size="large"
                            label="Name"
                            placeholder="Enter Name"
                            onChange={(e) => HandlePetOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.type}
                            name="petType"
                            size="large"
                            label="Type"
                            placeholder="Enter Type"
                            onChange={(e) => HandlePetOnChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.breed}
                            name="petBreed"
                            size="large"
                            label="Breed"
                            placeholder="Enter Breed"
                            onChange={(e) => HandlePetOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <DateTimePicker
                            label="Rabies Due Date"
                            name="petDueDate"
                            // rules={validationRules.date}
                            size="large"
                            formate="MM/DD/YYYY"
                            placeHolder="Enter Due Date (MM/DD/YYYY)"
                            // value={dueDate}
                            onChange={handleDateChange} //(dayjs(e["$d"]).format('MM/DD/YYYY')
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.license}
                            name="petLicense"
                            size="large"
                            label="License#"
                            placeholder="Enter License"
                            onChange={(e) => HandlePetOnChange(e)}
                          />
                        </Col>
                        <Col span={12}>
                          <TextInput
                            rules={validationRules.age}
                            name="petAge"
                            size="large"
                            label="Age"
                            placeholder="Enter Age"
                            onChange={(e) => HandlePetOnChange(e)}
                          />
                        </Col>
                        <Button
                          htmlType="submit"
                          class="submit-button"
                          style={{
                            marginLeft: 8,
                            borderRadius: 19,
                            color: "white",
                            backgroundColor: "#5A7890",
                            height: "2.5rem",
                            width: "8rem",
                          }}
                        >
                          Submit
                        </Button>
                      </Row>
                    </Form>
                  )}
                </Modal>
                <DynamicTable columns={petColumns} data={petData} />
              </section>
              <section>
                <p
                  className="text-center"
                  style={{
                    color: "#5A7890",
                    fontSize: "22px",
                    marginBottom: "4.5rem",
                    marginTop: "4rem",
                    fontWeight: "bold",
                  }}
                >
                  Lease Info
                </p>
                <Radio
                  label="Choose Type of Lease."
                  rules={validationRules.newOrRenew}
                  name="newOrRenew"
                  options={options}
                />
                <Row gutter={16}>
                  <Col span={12}>
                    <DateTimePicker
                      label="Start Date"
                      name="startDay"
                      rules={validationRules.date}
                      size="large"
                      formate="MM/DD/YYYY"
                      placeHolder="Enter Start Date"
                      onChange={handleStartDateChange}
                    />
                  </Col>
                  <Col span={12}>
                    <DateTimePicker
                      label="End Date"
                      name="endDay"
                      rules={validationRules.date}
                      size="large"
                      formate="MM/DD/YYYY"
                      placeHolder="Enter End Date"
                      onChange={handleEndDateChange}
                    />
                  </Col>
                </Row>
              </section>
              <p
                className="text-center"
                style={{
                  color: "#5A7890",
                  fontSize: "22px",
                  marginBottom: "4.5rem",
                  marginTop: "4rem",
                  fontWeight: "bold",
                }}
              >
                Supporting Documents
              </p>
              <UploadButton
                handlePreview={handlePreview}
                fileList={fileList}
                setFileList={setFileList}
                setPreviewImage={setPreviewImage}
                previewImages={previewImage}
                setPreviewOpen={setPreviewOpen}
                previewOpen={previewOpen}
                name="files"
                handleChange={handleChange}
                number={10}
              />
              {stateDisclaimer && (
                <div
                  style={{
                    marginTop: "4rem",
                    fontSize: "18px",
                    marginBottom: "2.5rem",
                    border: "1px solid #5A7890",
                    paddingLeft: "20px",
                    paddingTop: "0.9rem",
                    paddingRight: "20px",
                    paddingBottom: "1rem",
                  }}
                >
                  <p style={{ fontSize: "20px", color: "#5A7890" }}>
                    <b>State Disclaimers</b>
                  </p>
                  <div
                    style={{ fontSize: "16px" }}
                    dangerouslySetInnerHTML={{ __html: stateDisclaimer }}
                  />
                </div>
              )}
              {propertyDisclaimer && (
                <div
                  style={{
                    fontSize: "18px",
                    marginBottom: "3rem",
                    border: "1px solid #5A7890",
                    paddingLeft: "20px",
                    paddingTop: "0.9rem",
                    paddingRight: "20px",
                    paddingBottom: "1rem",
                  }}
                >
                  <p style={{ fontSize: "20px", color: "#5A7890" }}>
                    <b>Property Disclaimers</b>
                  </p>
                  <div
                    style={{ fontSize: "16px" }}
                    dangerouslySetInnerHTML={{ __html: propertyDisclaimer }}
                  />
                </div>
              )}

              {fee && (
                <>
                  <div style={{ fontSize: "18px", marginBottom: "2.5rem" }}>
                    <b>Note : </b>The selected property will charge an
                    additional fees of <strong>{fee}</strong>.
                  </div>
                  <section>
                    <p
                      className="text-center"
                      style={{
                        color: "#5A7890",
                        fontSize: "22px",
                        marginBottom: "4.5rem",
                        marginTop: "4rem",
                        fontWeight: "bold",
                      }}
                    >
                      Make A Payment
                    </p>

                    <Row gutter={16}>
                      <Col span={12}>
                        <TextInput
                          name="cardNumber"
                          size="large"
                          label="Credit Card Number"
                          placeholder="Enter Card Number"
                          onChange={(e) => {
                            handleCardNumberChange(e);
                          }}
                          rules={validationRules.cardNumber}
                        />
                      </Col>
                      <Col span={12}>
                        <TextInput
                          rules={validationRules.cvv}
                          name="cvv"
                          size="large"
                          label="CVV"
                          placeholder="Enter CVV"
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <TextInput
                          label="Expiry Date"
                          name="cardExpiry"
                          rules={validationRules.cardExpiry}
                          size="large"
                          placeholder="Select Expiry Date (MM/YY)"
                        />
                      </Col>
                      <Col span={12}>
                        <TextInput
                          rules={validationRules.cardHolderName}
                          name="cardHolderName"
                          size="large"
                          label="Card Holder Name"
                          placeholder="Enter Card Holder Name"
                        />
                      </Col>
                    </Row>
                  </section>
                </>
              )}
            </>

            <Button
              onClick={(e) => handleClearButton(e)}
              style={{
                borderRadius: 19,
                border: "1px solid #5A7890",
                color: "#5A7890",
                backgroundColor: "#fff",
                height: "2.5rem",
                width: "8rem",
              }}
            >
              Clear
            </Button>
            <Button
              htmlType="submit"
              class="submit-button"
              style={{
                marginLeft: 8,
                borderRadius: 19,
                color: "white",
                backgroundColor: "#5A7890",
                height: "2.5rem",
                width: "8rem",
              }}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Tenant;
